@custom-media --xl-min-screen-viewport (min-width: 1800px);

@custom-media --l-min-screen-viewport (min-width: 1200px);

@custom-media --m-min-screen-viewport (min-width: 600px);

/**
 * Temp
 * TODO: to be removed when the tokens would be available
 */

:root {
}@import "./VpAccordion.css";
@import "./VpArticleCard.css";
@import "./VpButton.css";
@import "./VpCheckbox.css";
@import "./VpFormError.css";
@import "./VpFormHelper.css";
@import "./VpFormLabel.css";
@import "./VpIconButton.css";
@import "./VpInput.css";
@import "./VpLink.css";
@import "./VpLinkList.css";
@import "./VpPrice.css";
@import "./VpProductCard.css";
@import "./VpRadioGroup.css";
@import "./VpScoreRating.css";
@import "./VpSpinner.css";
@import "./VpStarRating.css";
@import "./VpSticker.css";
@import "./VpTextarea.css";
